import { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import MailSentAnimation from '../assets/animation/MailSentAnimation';
import MessageFailed from '../assets/animation/MessageFailed';

const FormStyle = styled.form`
  width: 100%;
  .form-group {
    width: 100%;
    margin-bottom: 2rem;
  }
  label {
    font-size: 14px;
  }
  input,
  textarea {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: var(--grey);
    background-color: var(--dark);
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 1rem;
  }
  textarea {
    min-height: 250px;
    resize: vertical;
  }
  button[type='submit'] {
    background-color: var(--pri);
    color: var(--light);
    font-size: 14px;
    display: inline-block;
    outline: none;
    border: none;
    padding: 1rem 4rem;
    border-radius: 8px;
    cursor: pointer;
  }
  .anime-center {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default function ContactForm() {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const resetValue = () => {
    setName(null);
    setEmail(null);
    setMessage(null);
  };

  const [status, setStatus] = useState('');
  const sendEmail = (e) => {
    e.preventDefault();
    const { REACT_APP_SERVICEID, REACT_APP_TEMPLATEID, REACT_APP_PRIVATEKEY } =
      process.env;
    emailjs
      .sendForm(
        `${REACT_APP_SERVICEID}`,
        `${REACT_APP_TEMPLATEID}`,
        e.target,
        `${REACT_APP_PRIVATEKEY}`
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response);
          resetValue();
          setStatus('SUCCESS');
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
  };
  useEffect(() => {
    if (status === 'SUCCESS' || 'FAILED') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    }
  }, [status]);

  const renderAlert = () => (
    <div className='anime-center'>
      {status === 'SUCCESS' ? <MailSentAnimation /> : <MessageFailed />}
    </div>
  );

  return (
    <>
      {status && renderAlert()}
      <FormStyle onSubmit={sendEmail}>
        <div className='form-group'>
          <label htmlFor='name'>
            Your Name
            <input
              handleChange={() => setName(name)}
              type='text'
              id='name'
              name='name'
            />
          </label>
        </div>
        <div className='form-group'>
          <label htmlFor='email'>
            Your Email
            <input
              handleChange={() => setEmail(email)}
              type='email'
              id='email'
              name='email'
            />
          </label>
        </div>
        <div className='form-group'>
          <label htmlFor='message'>
            Your message
            <textarea
              handleChange={() => setMessage(message)}
              type='text'
              id='message'
              name='message'
            />
          </label>
        </div>

        <button type='submit'>Send</button>
      </FormStyle>
    </>
  );
}
