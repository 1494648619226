import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import PText from './PText';
import IntroImg from '../assets/images/abstract-v2.png';

const ContactBannerStyles = styled.div`
  margin: 20rem 10rem;
  background-color: var(--sec-sat);
  border-radius: 10px;
  border: solid 1px var(--grey);
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bannerImg {
    width: 50rem;
  }
  .contactBanner__wrapper {
    background-color: var(--light-blue);
    border-radius: 12px;
    padding: 5rem 0rem;
  }
  .contactBanner__heading {
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    
    margin: 20rem 5rem;
    .container {
      display: flex;
      flex-direction: column;
    }
    .bannerImg{
      width: 40rem;
    }
    .contactBanner__wrapper {
      text-align: center;
    }
 
  }
`;

export default function ContactBanner() {
  return (
    <ContactBannerStyles>
      <div className='container'>
        <div className='contactBanner__wrapper'>
          <PText>Have a project in mind</PText>
          <h3 className='contactBanner__heading'>
            Let's team up to assist you
          </h3>
          <Button btnText='Contact Now' btnLink='/contact' />
        </div>
        <div className='banner__right'>
          <img className='bannerImg' src={IntroImg} alt='Img' />
        </div>
      </div>
    </ContactBannerStyles>
  );
}
