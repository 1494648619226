import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  :root{
    --light: #ffff;
    --grey: #747D8A;
    --grey-2: #8f8f8f;
    --dark: #2B3139;
    --pri: #0652b9;
    --sec: #ff8b2c;
    --sec-sat: #FFF0E4;
    --pri-light: #91C0FF;
    --pri-sat: #D3E3FA;
    --fade-blue: hsl(214,93%,93%);
  }
  html{
    font-size: 10px;
    font-family: 'Montserrat';
    color:var(--dark);
  }
  ul,li{
    list-style: none;
  }
  a{
    text-decoration: none;
  }
  img, svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  button{
    outline: none
  }
  .container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
  }
  
  .st0{
    fill: var(--light)
  }
  .st1{
    fill: #a0a0a0;
  }
  .st2{
    fill: var(--pri)
  }
 
/* Smooth Scroll  */
  [data-scrollbar] {
    height: 100vh;
    overflow: hidden;
    background-color: var(--gray);
    .scroll-content {
      background-color: var(--dark);
    }
    .scrollbar-track.scrollbar-track-y {
      background: var(--dark);
      .scrollbar-thumb-y {
        background: var(--gray);
      }
    }
  }
`;
export default GlobalStyles;
