import React from 'react';
import {Error404} from '../layout';
import {Helmet} from 'react-helmet';

export default function PageNotFound() {
  return (
    <>
    <Helmet>
      <title>
        Page Not Found
      </title>
      <meta name="description" content="We are makers of Experience" />
    </Helmet>
      <Error404 />
     
    </>
  );
}
