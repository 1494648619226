import React from 'react';
import styled from 'styled-components';
import AnimationTwo from '../assets/animation/AnimationTwo';

const ThreeIntroStyles = styled.div`
  /* padding: 10rem 0; */
  .box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
    background-color: var(--pri);
    
    height: 80rem;
    
    margin: 0;
  }
  .threeintroSection__left,
  .threeintroSection__right {
    flex: 3;
    
   
  }
  h1{
    padding: 10rem;
    color: white;
  }
 
  @media only screen and (max-width: 950px) {
    .threeintroSection__left {
      flex: 4;
    }
    .threeintroSection__right {
      color: white;
      flex: 3;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 5rem 0;
    .box {
      flex-direction: column;
      text-align: left;
      height: 100vw
      justify-content: center;
      align-items: center;
      
    }
    .threeintroSection__left,
    .threeintroSection__right {
      width: 100%;
    }
    .threeintroSection__right {
      margin-top: 3rem;
    }
    h1{
   padding: 2rem;
    color: white;
  }
    
    }
   
  }
`;


export default function ThreeIntroSection() {
    return (
      <ThreeIntroStyles>
      <div className="box">
      <div className="threeintroSection__left">
          <AnimationTwo />
        </div>
        <div className="threeintroSection__right">
      <h1>Makers of Experience</h1>
      </div>
      </div>
      </ThreeIntroStyles>
    );
  }