import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { AboutInfoItem, ContactBanner } from '../components';

const AboutPageStyles = styled.div`
  @media only screen and (max-width: 768px) {
  }
`;

export default function About() {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name='description' content='We are makers of Experience' />
      </Helmet>
      <AboutPageStyles>
        <AboutInfoItem />
        <ContactBanner />
      </AboutPageStyles>
    </>
  );
}
