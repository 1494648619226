import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavMenu, Footer } from '../src/components';
import Home from './pages/Home';
import About from './pages/About';
import { Projects } from '../src/layout';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound';

export default function App() {
  useEffect(() => {
    ReactGA.initialize('G-HN9PQL8Q8Q');
    ReactGA.pageview('window.location.pathname + window.location.search');
  }, []);

  return (
    <>
      <Router>
        <NavMenu />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/contact' element={<Contact />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
