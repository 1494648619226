import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';


const AnimStyleTwo = styled.div`
 .containerTwo{ 
     width: 80vw;
     height: 80vh;
    

 }
  @media only screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const AnimationTwo = () => {
    const container = useRef(null)

    useEffect(() => {
      lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('../lottie/data.json')
      })
    }, [])
    
    return (
        <AnimStyleTwo>
      
        
        <div className="container" ref={container}></div>
     
      </AnimStyleTwo>
    );
  }

export default AnimationTwo;

