import React from 'react';
import styled from 'styled-components';
import SectionTitle from '../components/SectionTitle';
import AnimationThree from '../assets/animation/AnimationThree';
import PText from '../components/PText';
import Iconobg from '../assets/images/iconobg.png';

const TwoIntroStyles = styled.div`
/* background: var(--pri-light); */
padding: 20rem 10rem;
.container {
    
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .twointroSection__right {
    flex: 1;
  }
  .twointroSection__left {
    flex: 2;
  }

  .section-title {
    text-align: left;
  }
  .para {
    margin-top: 2rem;
    margin-left: 0;
  }

  @media only screen and (max-width: 768px) {
  
    .container {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .twointroSection__right {
      margin-top: 3rem;
    }
    .section-title {
      text-align: center;
    }
  }
`;

export default function TwoIntroSection() {
  return (
    <TwoIntroStyles style={{ backgroundImage: `url(${Iconobg})` }}>
      <div className='container' >
        <div className='twointroSection__left'>
          <SectionTitle
            subheading={
              <h3>
                "Who <span style={{ color: 'var(--pri)' }}>are we?</span>"
              </h3>
            }
            heading={false}
          />
          <PText>
            Creond is a digital agency that integrates design and technology to
            create top notch experience-centered products. Design and technology
            has moved past just creating products as aspects of it now can be
            automated but how individuals interact with your product cannot. We
            infuse experience design to create best products suited for your
            consumers. We are fundamentally{' '}
            <span style={{ color: 'var(--blue)' }}>makers of experience.</span>
          </PText>
        </div>
        <div className='twointroSection__right'>
          <AnimationThree />
        </div>
      </div>
    </TwoIntroStyles>
  );
}
