import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ProjectImg from '../assets/images/projectImg.png';
// import ProjectVid from '../assets/videos/comp.mp4';
import ReactPlayer from 'react-player';

const ProjectItemStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  padding-top: 5rem;
  .project-container {
    width: 100%;
    height: 400px;

    margin: 10rem 0;
    
  }
  .projectItem__img {
    width: inherit;
    height: inherit;
    border-radius: 12px;
    overflow: hidden;
    display: inline-block;
    border: 1px solid var(--grey);

    :hover {
      box-shadow: 0px 35px 30px var(--grey);
      transition: all 500ms ease-in-out;
      transform: scale(1.02);
    }

    img {
      height: 100%;
    }
  }
  .react-player {
    width: 100%;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;
    display: inline-block;
    /* transform: translate(0, -3%); */
  }
  .video-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .projectItem__info {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start; */
    margin-top: 1rem;
    background-color: var(--white);
    padding: 1rem;
    border-radius: 12px;
  }

  .projectItem__desc {
    margin-top: 1rem;
    color: var(--grey-2);
    
  }
  @media only screen and (max-width: 768px) {
    .project-container {
      margin: 3rem 0;
    }
    .projectItem__img {
      height: 350px;
    }
  }
`;

export default function ProjectItem({
  img = ProjectImg,
  title = 'Project Name',
  desc = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  link = '',
  vid = '',
}) {
  return (
    <ProjectItemStyles>
      <section className='project-container'>
        <a target='blank' href={link} className='projectItem__img'>
          <div className='video-center'>
            <ReactPlayer
              className='react-player'
              url={vid}
              type='video.mp4'
              width='100%'
              height='100%'
              loop={true}
              playing={true}
            />
          </div>
          <img src={img} alt='project' />
        </a>

        <div className='projectItem__info'>
          <Link to='#'>
            <h3 className='projectItem__title'>{title}</h3>
          </Link>
          <p className='projectItem__desc'>{desc}</p>
        </div>
      </section>
    </ProjectItemStyles>
  );
}
