import React from 'react';
import SectionTitle from './SectionTitle';
import AboutTitle from './AboutTitle';
import styled from 'styled-components';

const AboutItemStyles = styled.div`
  padding: 10rem 0;
  .aboutSection__wrapper {
    display: flex;
    gap: 3rem;
    margin-top: 5rem;
    
    
  }
h3{
  justify-content: left;
  padding: 2rem 0rem;
  
 
 
}
  p{
    padding: 2rem 0rem;
    
  }
  .list-items{
    display: column;
  }
  li{
  color: var(--blue);
}

  @media only screen and (max-width: 950px) {
   
  }

  @media only screen and (max-width: 768px) {
    .aboutSection__wrapper {
      flex-direction: column;
      text-align: center;
    }

    h3{
  padding: 2rem 0rem;
  
}
  }

`;

export default function AboutInfoItem() {
  return (
    <AboutItemStyles>
      <div className='container'>
        <SectionTitle
          heading='About'
          subheading='We are makers of Experience'
        />
        <div className='contactSection__wrapper'>
          <AboutTitle subtitle='Design' />

          <li className='list-items'></li>
        </div>
      </div>
    </AboutItemStyles>
  );
}
