import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import SVG404 from '../assets/svg/404svg';

const Error404PageStyles = styled.div`
  padding: 5rem 0;
  .error {
    display: flex;
    justify-content: center;
    gap: 10rem;
    align-items: center;
    /* margin: 0rem 10rem; */
  }
  .svgbox {
    width: 50rem;
    height: 50rem;
    margin: 4rem;
    padding-bottom: 8rem;
  }
  .righterrorinfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  h2 {
    font-size: 40px;
  }

  .button {
    margin: 2rem 0rem;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0 5rem 0;
    .error {
      flex-direction: column;
    }

    .svgbox {
      width: 30rem;
      height: 30rem;
      padding-bottom: 4rem;
    }

    .righterrorinfo {
      justify-content: flex-start;
      align-items: flex-start;
    }

    .button {
      margin: 2rem 0rem;
    }
    .error{
      gap: 0rem;
    }
  }
`;

export default function Error404() {
  return (
    <Error404PageStyles>
      <div className='error'>
        <div className='svgbox'>
          <SVG404 />
        </div>
        <div className='righterrorinfo'>
          <h2 className='errormessage'>OH OH!</h2>
          <p className='errormessage'>Lost in space, OVER!</p>
          <Button btnText='Take me home' btnLink='/' />
        </div>
      </div>
    </Error404PageStyles>
  );
}
