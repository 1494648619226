import React from 'react';
import styled from 'styled-components';
import {FooterCol, PText } from '.';


const FooterStyle = styled.div`
  background-color: var(--dark);
  padding-top: 10rem;
  
  .container {
    display: flex;
    gap: 3rem;
  }
  .footer__col1 {
    flex: 2;
  }
  .footer__col2,
  .footer__col3,
  .footer__col4 {
    flex: 1;
  }
 
  p{
      color: var(--light);
    }

  .copyright {
    background-color: var(--dark);
    text-align: left;
    padding: 1rem 0;
    margin-top: 5rem;
    .para {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      gap: 0rem;
      & > div {
        margin-top: 5rem;
      }
    }
    .footer__col1 .para {
      max-width: 100%;
    }
    .copyright {
      .container {
        div {
          margin-top: 0;
        }
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyle>
      <div className="container">
        <div className="footer__col1">
          
        </div>
        <div className="footer__col2">
          <FooterCol
            heading="Company"
            links={[
              {
                title: 'Welcome!',
                path: '/',
                type: 'Link',
              },
              {
                type: 'Link',
                title: 'What we do',
                path: '/about',
              },
              {
                type: 'Link',
                title: "What we've done",
                path: '/projects',
              },
              {
                type: 'Link',
                title: 'how to reach us',
                path: '/contact',
              },
            ]}
          />
        </div>
        <div className="footer__col3">
          <FooterCol
            heading="Contact Info"
            links={[
              {
                title: '+234 9086551208',
                path: 'tel:+2349086551208',
              },
              {
                title: 'business@creond.com',
                path: 'mailto:business@creond.com',
              },
              {
                title: 'FCT, Abuja, Nigeria',
              },
            ]}
          />
        </div>
        <div className="footer__col4">
          <FooterCol
            heading="Let's be social"
            links={[
              {
                title: 'Facebook',
                path: 'https://www.facebook.com/creond/?ref=pages_you_manage',
              },
              {
                title: 'Twitter',
                path: 'https://twitter.com/createbynd',
              },
              {
                title: 'Instagram',
                path: 'https://www.instagram.com/creond/',
              },
            ]}
          />
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <PText>
            © 2023 - Creond Innovations
          </PText>
        </div>
      </div>
    </FooterStyle>
  );
}
