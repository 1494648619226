import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { MdClose, MdMenu } from 'react-icons/md';
import Cee from '../assets/svg/cee';

const NavStyles = styled.nav`
  position: fixed;
  display: flex;
  gap: 5rem;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  background: var(--dark);

  #logo-box{
    width: 4rem;
    background: var(--light);
    border-radius: 3rem;
    padding: 0.3rem;
    margin-left: 10rem;
  }
  ul {
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    margin: 0 auto;
    width: 90%;
    text-align: center;
    li {
      display: inline-block;
      border-radius: 8px;
      transition: 0.3s ease background-color;
      color: var(--light);
      &:hover {
        background-color: var(--pri);
      }
    }
    a {
      display: inline-block;
      padding: 1rem 2rem;
      font-size: 16px;
      color: var(--light);
      outline: none;
      &:hover {
        color: var(--light);
      }
    }
    .active {
      color: var(--pri-light);
      :hover {
        color: var(--light);
      }
    }
  }
  .mobile-menu-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 4rem;
    cursor: pointer;
    display: none;
    outline: none;
    * {
      pointer-events: none;
    }
  }
  .navItems .closeNavIcon {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
    ul{
      flex-direction: column;
      justify-content: start;
    }
    .hide-item {
      transform: translateY(calc(-100% - var(--top)));
    }
    .mobile-menu-icon {
      display: block;
    }
    .navItems {
      --top: 1rem;
      transition: 0.3s ease transform;
      background-color: rgba(6, 82, 185, 0.3);
      backdrop-filter: blur(15px);
      padding: 2rem;
      width: 90%;
      max-width: 400px;
      height: 97vh;
      border-radius: 12px;
      position: absolute;
      right: 1rem;
      top: var(--top);
      .closeNavIcon {
        display: block;
        width: 3rem;
        margin: 0 0 0 auto;
        cursor: pointer;

        * {
          pointer-events: none;
          color: white;
        }
      }
      a {
        color: var(--light);
      }

      li {
        display: block;
        margin-bottom: 1rem;
        &:hover {
          background-color: var(--light);
          a {
            color: var(--pri);
          }
        }
        .active {
          color: var(--pri-light);

          font-size: 16px;
        }
      }
    }
  }
`;

export default function NavMenu() {
  const [showNav, setShowNav] = useState(false);
  return (
    <NavStyles>
      <div id='logo-box'  className={!showNav ? 'navItems hide-item' : 'navItems'}>

      <Cee />
      </div>
      <div
        className='mobile-menu-icon'
        onClick={() => setShowNav(!showNav)}
        role='button'
        onKeyDown={() => setShowNav(!showNav)}
        tabIndex={0}
      >
        <MdMenu />
      </div>
      <ul className={!showNav ? 'navItems hide-item' : 'navItems'}>
        <div
          className='closeNavIcon'
          onClick={() => setShowNav(!showNav)}
          role='button'
          onKeyDown={() => setShowNav(!showNav)}
          tabIndex={0}
        >
          <MdClose />
        </div>

        <li>
          <NavLink
            to='/'
            exact
            onClick={() => setShowNav(!showNav)}
            role='button'
            onKeyDown={() => setShowNav(!showNav)}
            tabIndex={0}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/about'
            onClick={() => setShowNav(!showNav)}
            role='button'
            onKeyDown={() => setShowNav(!showNav)}
            tabIndex={0}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/projects'
            onClick={() => setShowNav(!showNav)}
            role='button'
            onKeyDown={() => setShowNav(!showNav)}
            tabIndex={0}
          >
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/contact'
            onClick={() => setShowNav(!showNav)}
            role='button'
            onKeyDown={() => setShowNav(!showNav)}
            tabIndex={0}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </NavStyles>
  );
}
