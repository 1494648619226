import { v4 as uuidv4 } from 'uuid';
import AderemiImg from '../images/remiImg.png';
import WorkitdpImg from '../images/workitdp.png';
import Cinehub from '../images/cinehub-cover.png';
import JobeyetnImg from '../images/jobeyetn.png';
// import hsfg from  '../../layout/Error404'

// import Comp from '../videos/comp.mp4';
// import Comp2 from '../videos/comp2.mp4';

const projects = [
  {
    id: uuidv4(),
    name: 'Aderemi Olatubora',
    desc: 'Website design for a construction company',
    img: AderemiImg,
    link: 'http://www.aderemiolatubora.com/',
  },
  {
    id: uuidv4(),
    name: 'Cinehub',
    desc: 'A digital platform that allows users to stream movies and TV shows',
    img: Cinehub,
   
    link: '',
  },
  {
    id: uuidv4(),
    name: 'JobEye',
    desc: 'Developing a design for a job application app',
    img: JobeyetnImg,
    link: 'https://www.behance.net/gallery/137354333/JobEye-UI-UX-Design',
  },
  {
    id: uuidv4(),
    name: 'Workit',
    desc: 'UI design of a task management app',
    img: WorkitdpImg,
    link: 'https://www.behance.net/gallery/139967121/Workit-Branding-and-UI-Design',
  },
];

export default projects;
