import React from 'react';
import styled from 'styled-components';
import PText from './PText';

const AboutTitleStyle = styled.div`
  text-align: center;

  h2 {
    margin-top: 0.5rem;
    /* text-transform: uppercase; */
  }
  .section-about {
    display: flex;
    align-content: center;
    justify-content: space-between;
    background-color: var(--fade-blue);
    border-radius: 12px;
    padding: 5rem 5rem;
    text-align: center;
    border-left: 1rem solid var(--light-blue);
  }
  .half-one {
    margin: 0 10rem;
  }

  .half-two {
    margin: 0 5rem;
  
  }
  li{
font-size: 14px;
  }
  .section {
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    .section-about {
      flex-direction: column;
    }
  }
`;

export default function AboutTitle({ subtitle = 'Need Subheading' }) {
  return (
    <AboutTitleStyle className='about-title'>
      <PText>
        Creond is a team of strategists that dare to push beyond boundaries. We
        help businesses grow and scale by handling the complexities of design
        and technology whilst allowing the business to concentrate on what it
        does most excellently.
      </PText>

      <h3>Competences</h3>
      <br></br>
      <br></br>
      <section className='section-about'>
        <div className='half-one'>
          <h4>Design</h4>
          <li>UI/UX Design</li>
          <li>Branding</li>
          <li>Motion Graphics</li>
          <li>Video Ad Design</li>
        </div>

        <div className='half-two'>
          <h4>IT</h4>
          <li>Web Developement</li>
          <li>Software Developement</li>
          <li>Digital Markerting</li>
        </div>
      </section>
    </AboutTitleStyle>
  );
}
