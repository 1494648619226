import React from 'react';
import ContactSection from '../layout/ContactSection';
import {Helmet} from 'react-helmet';


export default function Contact() {
  return (
    <>
    <Helmet>
      <title>
        Contact Us
      </title>
      <meta name="description" content="We are makers of Experience" />
    </Helmet>
      <ContactSection />
     
    </>
  );
}
