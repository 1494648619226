import styled from 'styled-components';
import { SectionTitle, ProjectItem } from '../components';
import ProjectsInfo from '../assets/data/projects';
import { Helmet } from 'react-helmet';

const ProjectStyle = styled.div`
  padding: 10rem 10rem;
  .projects__allItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    margin-top: 5rem;
  }
  @media only screen and (max-width: 768px) {
    padding: 10rem 2rem;
    .projects__allItems {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default function Projects() {
  return (
    <>
      <Helmet>
        <title>Our Projects</title>
        <meta name='description' content='We are makers of Experience' />
      </Helmet>
      <ProjectStyle>
        <div className='container'>
          <SectionTitle
            heading='Projects'
            subheading='some of our recent works'
          />
        </div>
        <div className='projects__allItems'>
        {ProjectsInfo.map((item) => (
        <ProjectItem
          key={item.id}
          title={item.name}
          desc={item.desc}
          img={item.img}
          vid={item.vid}
          link={item.link}
        />
      ))}
        </div>
      </ProjectStyle>
    </>
  );
}
