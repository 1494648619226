import * as React from 'react';

const Cee = () => (
  <svg viewBox='0 0 100 100'>
    <path
    
      className='st0'
      d='m91.42 19.39-1.6-1.74c.55.56 1.08 1.14 1.6 1.74z'
    />
    <path
      className='st1'
      d='M68.88 79.6c.7-.31 1.43-.68 2.17-1.09-3.79 1.77-7.91 2.73-12.37 2.87-5.56-.18-10.55-1.5-14.98-3.97-4.77-2.66-8.48-6.37-11.15-11.14a30.692 30.692 0 0 1-3-7.64c-.67-2.71-1-5.58-1-8.62 0-3.04.33-5.91 1-8.62.67-2.71 1.67-5.26 3-7.64 2.66-4.77 6.38-8.48 11.15-11.14 4.43-2.47 9.42-3.8 14.98-3.97 4.46.15 8.58 1.1 12.37 2.87-.74-.42-1.47-.79-2.17-1.09-1.34-.58-2.74-1.08-4.18-1.48 3.13.43 6.07 1.29 8.84 2.58-.74-.42-1.47-.79-2.17-1.09-4.4-1.91-9.31-2.87-14.75-2.87-.42 0-.84.01-1.26.02-.41-.01-.82-.02-1.24-.02-6.46 0-12.27 1.39-17.45 4.17-5.17 2.78-9.24 6.63-12.2 11.57a30.423 30.423 0 0 0-3.91 10.54c-.35 1.99-.53 4.05-.53 6.19a35.648 35.648 0 0 0 .85 7.8c.73 3.18 1.92 6.16 3.59 8.94 2.96 4.93 7.03 8.79 12.2 11.57 5.17 2.78 10.99 4.17 17.45 4.17.42 0 .83-.01 1.24-.02.42.01.84.02 1.26.02 5.44 0 10.36-.96 14.75-2.87.7-.31 1.43-.68 2.17-1.09a30.684 30.684 0 0 1-8.84 2.58c1.45-.45 2.84-.95 4.18-1.53zM89.94 21.54l-4.27 3.94-4.2 3.87a35.66 35.66 0 0 0-4.12-3.89l4.22-3.88 4.45-4.09c.63.56 1.25 1.15 1.83 1.76l1.53 1.65c.2.2.38.42.56.64z'
    />
    <path
      className='st0'
      d='m89.39 79.11-1.53 1.65c.53-.53 1.04-1.08 1.53-1.65z'
    />
    <path
      className='st1'
      d='M89.94 78.46c-.18.22-.36.44-.56.65l-1.53 1.65c-.59.61-1.2 1.2-1.83 1.76l-4.45-4.09-4.22-3.88c1.43-1.15 2.81-2.45 4.12-3.89l4.2 3.87 4.27 3.93z'
    />
    <path
      className='st0'
      d='m89.39 20.89-1.53-1.65c.53.53 1.04 1.08 1.53 1.65z'
    />
    <path
      d='m81.57 78.43 4.45 4.09c-3.17 2.86-6.79 5.16-10.86 6.94-5.86 2.55-12.42 3.83-19.67 3.83-8.61 0-16.36-1.85-23.26-5.56-6.9-3.71-12.32-8.85-16.26-15.43-3.95-6.58-5.92-14.01-5.92-22.3s1.97-15.73 5.92-22.3 9.37-11.72 16.26-15.43c6.89-3.71 14.65-5.56 23.26-5.56 7.25 0 13.81 1.27 19.67 3.83 4.07 1.77 7.69 4.08 10.86 6.94l-4.45 4.09-4.22 3.88c-1.99-1.6-4.09-2.92-6.31-3.95-.74-.42-1.47-.79-2.17-1.09-4.4-1.91-9.31-2.87-14.75-2.87-6.46 0-12.27 1.39-17.45 4.17-5.17 2.78-9.24 6.63-12.2 11.57a30.423 30.423 0 0 0-3.91 10.54c-.35 1.99-.53 4.05-.53 6.19a35.648 35.648 0 0 0 .85 7.8c.73 3.18 1.92 6.16 3.59 8.94 2.96 4.93 7.03 8.79 12.2 11.57 5.17 2.78 10.99 4.17 17.45 4.17 5.44 0 10.36-.96 14.75-2.87.7-.31 1.43-.68 2.17-1.09 2.22-1.04 4.32-2.36 6.31-3.95l4.22 3.85z'
      className='st2'
    />
  </svg>
);

export default Cee;
