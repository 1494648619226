import React from 'react';
import {IntroSection, TwoIntroSection, ThreeIntroSection, ProjectsSection} from '../layout';

import ContactBanner from '../components/ContactBanner';
import {Helmet} from 'react-helmet';





export default function Home() {
  return (
    <div>
      <Helmet>
      <title>
        Creond - Design and Development 
      </title>
      <meta name="description" content="We are makers of Experience" />
    </Helmet>
      <IntroSection />
      <TwoIntroSection />
      <ThreeIntroSection /> 
      <ProjectsSection />
      <ContactBanner />
    </div>
   
  );
}
