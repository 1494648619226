import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';

const AnimStyleThree = styled.div`
  .container {
    width: 20vw;
  }
  @media only screen and (max-width: 768px) {
    width: 50vw;
  }
`;

const AnimationThree = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../lottie/cee.json'),
    });
  }, []);

  return (
    <AnimStyleThree>
      <div className='container' ref={container}></div>
    </AnimStyleThree>
  );
};

export default AnimationThree;
