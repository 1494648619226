import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`


  html{
    font-family: 'Montserrat';
    color: var(--black);
   
  }
  p,li{
    font-size: 16px;
  }
  *{
    font-family: 'Montserrat';
    color: var(--black);
    
  }
  h1{
    font-family: 'Montserrat';
    font-size: 40px; 
  }
  h2{
    font-family: 'Montserrat';
    font-size: 30px; 
  }
  h3{
    font-family: 'Montserrat';
    font-size: 24px; 
    
  }
  h4,h5,h6{
    font-family: 'Montserrat';
    font-size: 20px; 
    margin: 2rem;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
    p {
      font-size: 12px;
    }
    h2 {
      font-size: 16px;
    }
  }
`;

export default Typography;
