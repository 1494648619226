import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import PText from '../components/PText';
import SectionTitle from '../components/SectionTitle';
import IntroImg from '../assets/images/abstract-C.png';

const IntroSectionStyles = styled.div`
  padding: 15rem 0;
  background: var(--pri-sat);
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
  .introSection__left,
  .introSection__right {
    flex: 1;
  }
  .section-title {
    text-align: left;
  }
  .para {
    margin-top: 2rem;
    margin-left: 0;
  }
  .introSection__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin-top: 2rem;
  }
  @media only screen and (max-width: 950px) {
    .introSection__left {
      flex: 4;
    }
    .introSection__right {
      flex: 3;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      text-align: center;
    }
    .introSection__left,
    .introSection__right {
      width: 100%;
    }
    .introSection__right {
      margin-top: 3rem;
    }
    .section-title {
      text-align: center;
    }
    .para {
      margin: 0 auto;
      margin-top: 2rem;
    }
    .introSection__buttons {
      flex-direction: column;
      gap: 0rem;
      .button-wrapper,
      a {
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export default function IntroSection() {
  return (
  
    <IntroSectionStyles>
  
      <div className="container">
        <div className="introSection__left">
          <SectionTitle
            subheading=" We are"
            heading="Creond"
          />
          <PText>
          We define ideas and transform them into meaningful experiences. 
          </PText>
          <div className="introSection__buttons">
            <Button btnText="Explore" btnLink="/projects" />
            <Button btnText="Know more" btnLink="/about" outline />
          </div>
        </div>
        <div className="introSection__right">
          <img className="IntroImg" src={IntroImg} alt="Img" />
        </div>
      </div>
    </IntroSectionStyles>
  );
}
